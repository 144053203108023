<template lang="html">
    <article class="message" v-bind:id="id">
        <div class="block"> <!--  v-if="this.config" -->
            <div class="message-body">
                
                <!-- infowindow -->
                <div v-if="info" class="info-window-container"> 
                    <div class="box custom-box" style="padding: 8px;" id="infowindow">
                        <div class="fixed-grid has-4-cols mb-1">
                            <div class="grid">
                                <div class="cell is-col-span-3 is-capitalized"> <span> {{ info.other.poiTypeLabel }} </span></div>
                                <div class="cell has-text-right is-col-span-1" > <div class="button is-danger is-small is-outlined py-0 px-3" v-on:click="closeInfoWindow()"> X </div> </div>
                            </div>
                        </div>
                        <div class="grid mb-1"> 
                            <div class="cell"> <span v-html="info.other.targetName"> </span></div>
                        </div>
                        <div class="grid mb-1">
                            <div class="cell">
                                <div class="cell"> <span v-html="info.other.targetAddress"> </span></div>
                            </div>
                        </div>
                        <div class="grid mt-3 mb-1">
                            <div class="cell has-text-centered">
                                <div class="button is-link is-small"> <div v-on:click="gotoRecord(info.record.Id)">OPEN</div> </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div id="mapwithmarkers" style="height:650px;"></div>
            </div>
        </div>
    </article>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader"

export default {
    name: 'bulmaMapwithmarkers',
    components: { },
    props: {
        id: String
    },
    data() {
        return {
            loader: {}, 
            map: {}, 
            marker: undefined,
            selPosition: {
                lat: 0,
                lng: 0
            },
            poiMap: {},
            info: undefined
        }
    },
    
    computed: {
        config: function () {
            console.log('this.id', this.id);
            return  this.$store.getters.getObject(this.id).data;
        },
        busy: function () {
            return this.$store.state.busy;
        }
    },
    
    watch: {
        config: function (newVal) {
            // if (newVal && this.loader) {
            //     this.loadMap();
            // }
        }
    },
    
    mounted: async function () {
        console.log('this.config', this.config);
        if (this.config) {
            this.loader = await this.$store.dispatch('googleMapLoader', {
                loader: Loader,
                apiKey: this.config.apiKey
            })
            console.log('loader map!', this.loader);
            
            this.$nextTick(() => {
                this.loadMap();
            })
        } else {
            console.log("tester")
        }
    },
    
    async created() {},
    
    methods: {
        getFieldValue: function (obj, path) {
            // eslint-disable-next-line no-redeclare
            for (var i = 0, path = path.split('.'), len = path.length; i < len; i++) {
                if (obj) {
                    obj = obj[path[i]];
                }
            }
            return obj;
        },
        
        loadMap: function () {
            this.loader.load().then(async () => {
                // eslint-disable-next-line no-undef
                let geocoder = new google.maps.Geocoder();
                
                // eslint-disable-next-line no-undef
                const { Map } = await google.maps.importLibrary("maps");
                const opRes = await this.getPosition();
                if(opRes.success) {
                    this.selPosition.lat = opRes.position.coords.latitude;
                    this.selPosition.lng = opRes.position.coords.longitude;    
                }
                
                let styles = { 
                    default: [{
                        "featureType": "poi",
                        "stylers": [{ "visibility": "off" }]
                    }]
                }
                this.map = new Map(document.getElementById("mapwithmarkers"), {
                    zoom: 13,
                    mapId: "FTI_Project",
                    mapTypeControl: false,
                    rotateControl: false,
                    scaleControl: false,
                    fullscreenControl: false,
                    styles: styles
                });
                this.map.setCenter(this.selPosition);
                
                this.setMarker();
                this.getRecords();
            });
            
        },
        
        getPosition: function() {
            return new Promise(function(resolve, reject) {
                if(navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(function(position) {
                        console.log('position ok');
                        console.log(position);
                        return resolve({ success: true, position: position });
                    }, function()  {
                        return resolve({ success: false });
                    });
                } else {
                    return resolve({ success: false });
                }
            });
        },
        
        setMarker: async function () {
            // eslint-disable-next-line no-undef
            const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
            this.marker = new AdvancedMarkerElement({
                map: this.map,
                position: new google.maps.LatLng(this.selPosition.lat, this.selPosition.lng),
                title: 'selected position'
            });
        },
        
        getRecords: async function() {
            console.log('getRecords');
            this.array_poi=[];

            console.log('this.config', this.config);
            for(let query of this.config.queries) {
                this.execQuery(this.id, query, this.map, this.poiMap);
            } 
        },

        execQuery: async function(id, queryInfo, map, poiMap) {
            console.log(queryInfo);

            let properties = [];
            for(let col of queryInfo.cols) {
                properties.push(col.fieldname)
            }

            let res = await this.$store.dispatch('search_records', { id: id, object: queryInfo.object, filters: queryInfo.filters, properties: properties, limit: queryInfo.limit });
            if (res.data) {
                console.log(res.data);
                if(res.data.done) {
                    this.addMarkersToMap(res.data.records, map, queryInfo.object.toLowerCase(), poiMap)
                }
            }
        },
        
        addMarkersToMap: async function(records, gmap, poiType, poiMap) {
            const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");
            const pinMap = {
                'lead': { background: "#4AA39A", borderColor: "#42928a", glyphColor: "white" },
                'account': { background: "#5B67E0", borderColor: "#3f489c", glyphColor: "white" },
                'opportunity': { background: "#EC683E", borderColor: "#a5482b", glyphColor: "white" },
                'default': { background: "#f8e474", borderColor: "#f3d113", glyphColor: "white" }
            }
            
            for(let record of records) {
                let geoPos = this.getRecordPosition(record, poiType);
                
                if(geoPos == '') {
                    continue;
                }
                
                let other = this.setOtherInfoPOI(record, poiType, pinMap, PinElement);
                
                let marker = new AdvancedMarkerElement({
                    map: gmap,
                    position: new google.maps.LatLng(geoPos.lat, geoPos.lng),
                    title: record.Name,
                    content: other.pin.element
                });
                
                marker.addListener('click', () => {
                    this.clickMarker(record.Id);
                });
                
                poiMap[record.Id] = { type: poiType, marker: marker, record: record, other: other };
            }
        },
        
        setOtherInfoPOI: function(record, poiType, pinMap, PinElement) {
            let other = { isAccount: poiType == 'account', isLead: poiType == 'lead' };
            if(record.Name) {
                other.targetName = record.Name
            }
            
            let composedAddress = '';
            if(poiType == 'lead') {
                if(record.Street) composedAddress += record.Street + ' ';
                if(record.City) composedAddress += record.City + ' ';
                if(record.PostalCode) composedAddress += record.PostalCode + ' ';
                if(record.State) composedAddress += record.State + ' ';
                if(record.Country) composedAddress += record.Country + ' ';
                other.poiTypeLabel = 'lead';
            }
            if(poiType == 'account') {
                if(record.BillingStreet) composedAddress += record.BillingStreet + ' ';
                if(record.BillingCity) composedAddress += record.BillingCity + ' ';
                if(record.BillingPostalCode) composedAddress += record.BillingPostalCode + ' ';
                if(record.BillingState) composedAddress += record.BillingState + ' ';
                if(record.BillingCountry) composedAddress += record.BillingCountry + ' ';
                other.poiTypeLabel = 'customer';
            }
            if(poiType == 'opportunity') {
                if(record.Street__c) composedAddress += record.Street__c + ' ';
                if(record.City__c) composedAddress += record.City__c + ' ';
                if(record.Postal_Code__c) composedAddress += record.Postal_Code__c + ' ';
                if(record.State__c) composedAddress += record.State__c + ' ';
                if(record.Country__c) composedAddress += record.Country__c + ' ';
                other.poiTypeLabel = 'project';
            }
            other.targetAddress = composedAddress.trim();

            let options = (pinMap[poiType]) ? pinMap[poiType] : pinMap['default'];
            other.pin = new PinElement(options);

            return other;
        },
        
        getRecordPosition(record, poiType) {
            let lat = '';
            let lng = '';
            if(poiType == 'lead') {
                lat = record.Latitude;
                lng = record.Longitude;
            }
            if(poiType == 'account') {
                lat = record.BillingLatitude;
                lng = record.BillingLongitude;
            }
            if(poiType == 'opportunity') {
                if(record.Geolocation__c) {
                    lat = record.Geolocation__c.latitude;
                    lng = record.Geolocation__c.longitude;  
                } 
            }
            
            if(lat == lng && lat == '') {
                return '';
            }
            
            return { lat, lng };
        },
        
        isAdmin(){
            return this.$store.getters.isAdmin;
        },
        
        clickMarker: async function (Id) {
            console.log('clickMarker:'+Id);
            let poi = this.poiMap[Id];
            if (poi) {
                console.log(poi);
                this.openInfoWindow(poi.record.Id);                    
            }
            return;
        },
        
        openInfoWindow: function (Id) {
            const self = this;
            self.closeInfoWindow();
            
            let poi = self.poiMap[Id];
            self.info = new google.maps.InfoWindow();
            self.info.marker = poi.marker;
            self.info.record = poi.record;
            self.info.other = poi.other;
            
            self.$nextTick(function() {
                let iw = document.getElementById('infowindow');
                console.log(iw);
                self.info.setContent(iw.innerHTML);
                self.info.open(self.map, poi);
            });
        },
        
        closeInfoWindow() {
            if(this.info) {
                this.info.close();
            }
            this.info = undefined;
        },
        
        gotoRecord(recordId) {
            let poi = this.poiMap[recordId];
            
            if(poi.record && poi.type) {
                let path;
                if(poi.type == 'account') { path = '/customers/d/' }
                if(poi.type == 'lead') { path = '/leads/d/' }
                if(poi.type == 'opportunity') { path = '/projects/d/' }
                
                if(path) {
                    this.$router.push( { path: path + recordId } );
                }
            }
        }
    }
}
</script>

<style scoped="scoped">

.info-window-container {
    z-index: 100;
    position: fixed;
    width: 30vw;
    margin-top: 1rem;
    margin-left: 50%;
    transform: translateX(-50%);
}

.custom-box {
    background-color: white;
    border: 1px solid;
    color: black;
}

</style>