<template>
  
  <div ref="dropdownContainer" class="navbar-item has-dropdown is-fullheight" :class="{ 'is-active': dropdownActive }"> <!-- is-left -->
    <a class=" is-fullheight" @click="isSubscribed ? toggleDropdown() : notificationRequest()" >
      <span class="icon">
        <i class="fas fa-bell"></i>
      </span>
    </a>
    
    <div class="navbar-dropdown is-narrow dropdown-left">
      
      <div v-if="notifications.length === 0" class="navbar-item">
        <p class="has-text-grey">No Notifications</p>
      </div>
      
      <div v-else>
        <a 
        class="navbar-item is-flex is-align-items-center hoverClass" 
        v-for="(item, index) in notifications" 
        :key="item.Id"
        :style="index < notifications.length - 1 ? { 'border-bottom': '1px solid #9c9c9c39' } : null">
        <div class="is-flex-grow-1 overflow">
          <a :href="item.url" >    <!--  target="_blank"  -->
            <p v-bind:title="item.title" class="title cleanText is-6 mb-0">{{ item.title }}</p>
            <p v-bind:title="item.body" class="content cleanText">{{ item.body }}</p>
          </a>
        </div>
        
        <span 
          v-if="item.title!='notifications have been activated'"
          class="icon is-pulled-right ml-3 mt-1" 
          @click.stop="archiveNotification(item.Id)">
          <i class="fas fa-solid fa-box-archive"></i>
        </span>
    </a>
  </div>
</div>
</div>
</template>

<script>
export default {
  data() {
    return {
      dropdownActive: false,
      // notifications: [],
      isSubscribed: false,
      showNotificationMenu: false,
      isListenerSet: false,
    };
  },
  computed: {
    notifications: function(){
      return this.$store.getters.getNotificationHistory;
    }
  },
  mounted() {
    this.checkSubscription();
    document.addEventListener('click', this.handleClickOutside);
    if(this.useListener) this.fetchNotification()
    

  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  props: {
    useListener: {
      required: false,
    }
  },
  methods: {
    onServiceWorkerMessage(event) {
      if (event.data && event.data.type === "PUSH_NOTIFICATION") {
        console.log("Notification received:", event.data.data);
        this.$store.commit("PUSH_NOTIFICATION", event.data.data);
      }
    },
    async fetchNotification(){
      await this.checkSubscription();

      if(this.isSubscribed && !this.isListenerSet){

        console.log("--> notification listener");

        navigator.serviceWorker.removeEventListener("message", this.onServiceWorkerMessage);
        navigator.serviceWorker.addEventListener("message", this.onServiceWorkerMessage);
        this.isListenerSet = true;
      }
      
    },
    handleClickOutside(event) {
      if (this.dropdownActive && 
          this.$refs.dropdownContainer && 
          !this.$refs.dropdownContainer.contains(event.target)) {
        this.dropdownActive = false;
      }
    },
    toggleDropdown() {
      
      this.dropdownActive = !this.dropdownActive;
      // console.log("dropdown", this.dropdownActive);
    },
    async archiveNotification(Id) {
      await this.$store.dispatch('update_record', { object: "Task", recordId: Id, properties: { Type: "NotificationArchived" } });
      this.$store.commit('SET_NOTIFICATIONS', (this.notifications.filter(notification => notification.Id !== Id)));
    },
    //da fare azionare al pulsante
    async notificationRequest(){
      const permission = await window.confirm("do you want to enable notifications for this app?");
      if(!permission) {
        console.log("notification pop up refused");
        return false;
      }else console.log("popup ok");
      
      if(!await this.enableNotifications()){
        console.log("notification needed");
        return false;
      }
      
      try {
        const registration = await navigator.serviceWorker.ready;
        
        // Chiave pubblica VAPID
        const publicVapidKey = 'BBOBTmsYjl2SNkwbKddi0stTi0EOJPfxKETo_1CiX3P8VxRQ3nSozxtt3gRQmVX7ZJzsAM0eQfqn5flxZYpv8nc';
        
        // Invoca pushManager.subscribe direttamente dall'evento click
        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: this.urlBase64ToUint8Array(publicVapidKey),
        });
        
        console.log('subscription completed:', subscription);
        
        this.$store.dispatch("saveSubscription", { subscription: subscription })
        this.fetchNotification();
        alert('notifications successfully activated!');
      } catch (error) {
        console.error('Error while subscribing:', error);
        alert('Unable to turn on notifications.');
      }
      this.checkSubscription();
      return true;
      //qui funzione per salvare nel db l'iscrizione.
      //come: this.$store.dispatch("saveSubscription", { id: this.id }), ma senza l'iscirizone visto che è già presente.
    },
    urlBase64ToUint8Array(base64String) {
      const padding = '='.repeat((4 - base64String.length % 4) % 4);
      const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);
      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },
    async checkSubscription() {
      if (!("serviceWorker" in navigator)) {
        console.log("browser dose not support Service Worker.");
        return;
      }
      
      try {
        const registration = await navigator.serviceWorker.ready;
        const subscription = await registration.pushManager.getSubscription();
        this.isSubscribed = !!subscription; // Imposta lo stato in base all'iscrizione
      } catch (error) {
        console.error("error while checking entries:", error);
      }
    },
    openNotifications() {
      // let data = {
      //   title: "Notification Menu",
      //   processId: "notificationProcess",
      //   verbId: "default",
      //   mettiilpaddingstretto:true
      
      // }
      // this.$store.commit("PUSH_PROCESS_STATUS", data);
      this.showNotificationMenu = !this.showNotificationMenu;
      // console.log("notification history showed");
    },
    async enableNotifications() { 
      
      console.log("--> enableNotifications");
      if ('Notification' in window && 'serviceWorker' in navigator) {
        console.log("request notifications");
        const permission = await Notification.requestPermission();
        
        if (permission === 'granted') {
          console.log("allowed notifications");
          return true;
        } else {
          console.error('Notification permissions denied.');
          
        }
      } else {
        console.error('Browser does not support push notifications.');
      }
      return false;
    },
  },
};
</script>







<style scoped>
/* Colori e tema */
.navbar-dropdown {
  /* --dropdown-bg: var(white); */
  --dropdown-hover: var(--bulma-primary);  /* colore hover */
  --dropdown-divider: var(black);
  --text-color: var(--bulma-text);
  --text-invert: var(--bulma-primary-invert);
}
 
/* Struttura base */
.navbar-dropdown {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  width: 300px;
  background-color: var(--dropdown-bg);
  border-radius: var(--bulma-radius-large);
  border: solid 1px #9c9c9c39;
  box-shadow: 0 .5em .5em rgba(255, 255, 255, 0.155);
  z-index: 20;
}

.navbar-dropdown .navbar-item {
  padding: 0.5rem 1rem;
  
  background-color: var(--dropdown-bg);
  color: var(--text-color);
  text-decoration: none !important;
}

.navbar-dropdown .navbar-item .title,
.navbar-dropdown .navbar-item .content {
  color: inherit;
}

/* Interazioni hover */
.navbar-dropdown .navbar-item:hover {
  background-color: var(purple);
  /* color: var(--text-invert); */ 
}

/* Elementi specifici */
.navbar-dropdown .has-text-grey {
  color: var(--bulma-grey) !important;
}

.navbar-item.has-dropdown.is-active .navbar-dropdown {
  display: block;
}
/* a.navbar-item.is-flex.is-align-items-center.hoverClass{
  background-color: white;
} */
.overflow{
  width: 93%;
  overflow: hidden;
}
/* Responsive */
@media screen and (min-width: 1024px) {
  .navbar-dropdown {
    min-width: 35vw;
    inset-inline-start: auto;
    background-color: var(--bulma-scheme-main);
  }
  
  .navbar-dropdown .navbar-item {
    transition: all 0.2s ease-in-out;
  }
  .hoverClass:hover{
    background-color: var(--bulma-link) !important;
    color: white !important;
  }
  
}

@media screen and (max-width: 1024px) {
  .navbar-dropdown {
    min-width: 15vw;
    inset-inline-start: auto;
    border-bottom: 1px solid var(--dropdown-divider);
    background-color: var(--bulma-scheme-main);
  }
  
  .navbar-dropdown .navbar-item {
    padding: 0.3rem 0.5rem;
  }
  
  .navbar-dropdown .navbar-item:hover {
    background-color: var(--dropdown-bg);
    color: var(--text-color);

    
  }
  
}
</style>
