import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './translate.js'

Vue.config.productionTip = true

import bulmaTabBar from './components/bulma_tabbar.vue'
import bulmaSubTab from './components/bulma_subtab.vue'
import bulmaModal from './components/bulma_modal.vue'
import bulmaContainer from './components/bulma_container.vue'
import bulmaTable from './components/bulma_table.vue'
import bulmaForm from './components/bulma_form.vue'
import bulmaRows from './components/bulma_rows.vue'
import bulmaBottomBar from './components/bulma_bottombar.vue'
import bulmaSections from './components/bulma_sections.vue'
import bulmaField from './components/bulma_field.vue'
import bulmaFieldSet from './components/bulma_fieldset.vue'
import bulmaFieldSet2 from './components/bulma_fieldset2.vue'
import bulmaActions from './components/bulma_actions.vue'
import bulmaMap from './components/bulma_map.vue'
import bulmaTimeline from './components/bulma_timeline.vue'
import bulmaPagination from './components/bulma_pagination.vue'
import bulmaActivities from './components/bulma_activities.vue'
import bulmaBuddy from './components/bulma_buddy.vue'
import bulmaHelp from './components/bulma_help.vue'
import bulmaGraph from './components/bulma_graph.vue'
import bulmaLocate from './components/bulma_locate.vue'
import bulmaSimpleTable from './components/bulma_simpletable.vue'
import bulmaGoogleMyBusiness from './components/bulma_googleMyBusiness.vue'
import bulmaEmpty from './components/bulma_empty.vue'
import bulmaApproval from './components/bulma_approval.vue'
import notificationManager from './components/notificationManager.vue'
import bulmaConversion from './components/bulma_conversion.vue'
import bulmaMapwithmarkers from './components/bulma_mapwithmarkers.vue'
import bulmaUpload from './components/bulma_upload.vue'


Vue.component('bulmaBottomBar', bulmaBottomBar);
Vue.component('bulmaTabBar', bulmaTabBar);
Vue.component('bulmaSubTab', bulmaSubTab);
Vue.component('bulmaModal', bulmaModal);
Vue.component('bulmaContainer', bulmaContainer);
Vue.component('bulmaTable', bulmaTable);
Vue.component('bulmaForm', bulmaForm);
Vue.component('bulmaRows', bulmaRows);
Vue.component('bulmaSections', bulmaSections);
Vue.component('bulmaField', bulmaField);
Vue.component('bulmaFieldSet', bulmaFieldSet);
Vue.component('bulmaFieldSet2', bulmaFieldSet2);
Vue.component('bulmaActions', bulmaActions);
Vue.component('bulmaMap', bulmaMap);
Vue.component('bulmaTimeline', bulmaTimeline);
Vue.component('bulmaPagination', bulmaPagination);
Vue.component('bulmaActivities', bulmaActivities);
Vue.component('bulmaBuddy', bulmaBuddy);
Vue.component('bulmaHelp', bulmaHelp);
Vue.component('bulmaGraph', bulmaGraph);
Vue.component('bulmaLocate', bulmaLocate);
Vue.component('bulmaSimpleTable', bulmaSimpleTable);
Vue.component('bulmaEmpty', bulmaEmpty);
Vue.component('bulmaHelp', bulmaHelp);
Vue.component('bulmaLocate', bulmaLocate);
Vue.component('bulmaSimpleTable', bulmaSimpleTable);
Vue.component('bulmaGoogleMyBusiness', bulmaGoogleMyBusiness);
Vue.component('bulmaApproval', bulmaApproval);
Vue.component('bulmaConversion', bulmaConversion);
Vue.component('notificationManager', notificationManager);
Vue.component('bulmaMapwithmarkers', bulmaMapwithmarkers);
Vue.component('bulmaUpload', bulmaUpload);

var myApp=new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

window.myApp=myApp;

import numeral from 'numeral'

if (numeral.locales['it'] === undefined) {
  numeral.register('locale', 'it', {
      delimiters: { thousands: '.', decimal: ',' },
      abbreviations: { thousand: 'k', million: 'm', billion: 'b', trillion: 't' },
      currency: { symbol: '€'}
  });
}

if (numeral.locales['us'] === undefined) {
  numeral.register('locale', 'us', {
      delimiters: { thousands: ',', decimal: '.' },
      abbreviations: { thousand: 'k', million: 'm', billion: 'b', trillion: 't' },
      currency: { symbol: '$'}
  });
}


//per mandare notifiche
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
      .register('/service-worker.js', {scope: '/'}) // Percorso del file service-worker.js
      .then((registration) => {
          console.log('Service Worker registrato con successo:', registration);

          // Verifica se il browser supporta PushManager
          if ('PushManager' in window) {
              console.log('notification supported by browser');
          }
      })
      .catch((error) => {
          console.error('Errore nella registrazione del Service Worker:', error);
      });
} else {
  console.error('notification not supported by browser.');
}



/*
router.afterEach(( to, from ) => {
//  console.log('ga:'+to.path)
});
*/

