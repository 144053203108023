<template lang="html">

    <div>
        <table class="table is-fullwidth is-bordered">
            <tbody>
                <tr v-for="obj in data.objects" :key="obj.name">

                    <!-- CREATE -->
                    <td v-if="obj.create_fields">
                        <label class="label">
                            <input type="radio" :name="obj.name" value="create" v-model="selected_choices[obj.name]" @change="saveRecord(obj.newField, obj.create_fields)"/>
                            Create New {{ obj.label }}
                        </label>
                        <div v-for="field in obj.create_fields" class="py-1">
                            <bulmaField :id="field.name" :data="getField(field, obj)" :disabledProp="!selected_choices[obj.name] || selected_choices[obj.name] != 'create'" @saveNewRecord="saveRecord(obj.newField, obj.create_fields)"></bulmaField>
                        </div>
                        <p v-if="obj.optional" class="">
                            <input type="radio" :name="obj.name" value="dontCreate" v-model="selected_choices[obj.name]" @change="saveRecord(obj.newField, obj.create_fields, true)"/>
                            Don't create {{ obj.label }} upon conversion
                        </p>
                    </td>

                    <!-- EXISTING -->
                    <td v-if="obj.existing_fields">
                        <label class="label">
                            <input type="radio" :name="obj.name" value="existing" v-model="selected_choices[obj.name]" @change="saveRecord(obj.newField, obj.existing_fields)"/>
                            Choose Existing {{ obj.label }}
                        </label>
                        <div v-for="field in obj.existing_fields" class="py-1">
                            <bulmaField :id="field.name" :data="getField(field)" :disabledProp="!selected_choices[obj.name] || selected_choices[obj.name] != 'existing'"></bulmaField>
                        </div>
                    </td>

                </tr>
            </tbody>
        </table>
    </div>
  
</template>
  
<script>

export default {
    name: 'bulmaConversion',
    components: {},
    props: {},
    data: function () {
        return {
            selected_choices: {}
        }
    },
    watch: {},
    computed: {
        id() {
            var process = this.$store.getters.getProcessStatus.processId
            var verb = this.$store.getters.getVerb( this.$store.getters.getProcess(process), 'c' )
            console.log('VERB',verb)
            return verb.pageid
        },
        data() {
            return this.$store.getters.getObject(this.id).data
        },
    },
    mounted: function() {
        this.$store.commit('CLEAR_CONVERT_INFO')
        this.$store.commit('SET_RECORDS_STATE', { id: this.$store.getters.getProcessStatus.processId, state: 'EDIT' })

        for(var obj of this.data.objects) {
            this.saveRecord(obj.newField, obj.create_fields)
            this.$set(this.selected_choices, obj.name, 'create')
        }
    },
    methods: {
        getField (field, obj) {
            if(field.type == 'picklist') {
                if (!this.$store.state.database.has(obj.table)) {
                    field.type = 'text'
                    return { ...field, "convertLead": true }
                }

                let table=this.$store.state.database.get(obj.table);
                if (table.has(field.newField)) {
                    let f2={ ...table.get(field.newField) }
                    f2.name = field.name
                    return { ...f2, "convertLead": true }
                }
            }
            return { ...field, "convertLead": true }
        },
        saveRecord(obj, fields, optional) {
            if(!optional) {
                var value = {}
                for(var f of fields) {
                    if(this.$store.getters.getRecordData[f.name]) {
                        if(f.newField) value[f.newField] = this.$store.getters.getRecordData[f.name] 
                        else value[f.name] = this.$store.getters.getRecordData[f.name] 
                    }
                }
                this.$store.commit('SET_CONVERT_INFO', { field: obj, value: JSON.stringify(value) })
            } else this.$store.commit('SET_CONVERT_INFO', { field: obj, value: '' })

        }
    }
}
</script>

<style>
.pe-none {
    pointer-events: none;
}
/* .table td, .table th {
    border: 0
} */
</style>
  