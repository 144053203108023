<template lang="html">
    
    <div class="block" v-if="hasConfig()" v-bind:id="id">
        
        <div class="tabs is-boxed">
            <ul>
                <li
                v-on:click="tabClick(index)"
                v-for="(tab, index) in tabs"
                v-bind:key="tab.name"
                v-bind:class="{ 'is-active': tab.name==config.active_tab }">
                <a>{{$t(tab.name)}}</a>
                
            </li>
            
            <button class="button ml-1" v-on:click="showModal = true" v-if="isAdmin() && getEditProps">
                <span class="icon is-small">
                    <i class="fas fa-pen"></i>
                </span> 
            </button>
        </ul>
        
    </div>
    <div class="">
        <component v-if="active_type" v-bind:is="active_type" v-bind:id="active_id"></component>
        
        <creator
        @create-component="addComponent"
        v-if="isAdmin()"
        v-bind:editButton="true"
        />
        
    </div>
    
    
    <bulmaSubTabProps
        v-bind:config="this.config"
        v-bind:id="this.id"
        v-bind:editmode="editmode"
        v-on:close="closeModal"
        v-if="showModal && isAdmin()"
    ></bulmaSubTabProps>
    
    
    <button class="button mt-4 " v-on:click="showModal = true" v-if="isAdmin() && getEditProps && !hasConfig">Initialise Subtab</button>
    
   
</div>
<div v-else-if="!hasConfig() && isAdmin()">
    <button class="button" v-on:click="showModal=!showModal">Inizializza Subtab</button>
    <bulmaSubTabProps
    v-bind:config="this.config || {}"
    v-bind:id="this.id"
    v-bind:editmode="editmode"
   
    v-on:close="closeModal"
    v-if="showModal"></bulmaSubTabProps>
</div>

</template>

<script>
import creator from '../components/creator.vue'
import bulmaSubTabProps from '../components/bulma_subtabprops.vue'
export default {
    name: 'bulmaSubTab',
    components: {
        bulmaSubTabProps,
        creator
    },
    props: {
        id: String
    },
    computed: {
        active_type: function () {
            let t = this.config.tabs.find((t) => {
                return t.name == this.config.active_tab
            })
            if (t) return t.type;
            return "";
        },
        active_id: function () {
            let t = this.config.tabs.find((t) => {
                return t.name == this.config.active_tab
            })
            if (t) return t.id;
            return "";
        },
        config: function () {
            return this.$store.getters.getObject(this.id).data;
        },
        editmode: function () {
            return this.$store.getters.getEditMode(this.id);
        },
        romode: function () {
            return this.$store.getters.getROMode(this.id);
        },
        getEditProps(){
            return this.$store.getters.getEditProps;
        },
        tabs() {
            return this.config.tabs.filter( t => this.isEnabled(t) == true)
        },
       
    },
    data() {
        return {burger_open: false, showModal: false, components: []}
    },
    unmounted: function() {
        if(this.hasConfig()){
            console.log('clean subtab to:', this.config.default_active_tab);
            this.updateConfig("active_tab", this.config.default_active_tab );
        }
    },
    mounted: function() {
        if(this.hasConfig()){

            // if(!this.$store.state.config[this.id]) {
                console.log('reset subtab to:', this.config.default_active_tab);
                this.updateConfig("active_tab", this.config.default_active_tab );
            // }
        }
        
    },
    methods: {
        hasTabs() {
            return this.config && 'tabs' in this.config;
        },
        isEnabled: function (tab) {
            if(tab.disabled) return tab.disabled
            if (!tab.fieldname) return true;
            let r=this.$store.getters.getRecordData;
            if(!r) return false
            return r[tab.fieldname];
        },
        closeModal() {
            this.showModal = false;
            
        },
        async addComponent(data) {
            
            let tabs = [...this.config.tabs];
            let index;
     
            let assignedTab = this.config.tabs.find(tab => tab.name ==this.config.active_tab);

            assignedTab.id=data.component.id;
            assignedTab.type=data.component.type;
            console.log("add component", assignedTab)
            await this.$store.dispatch('setObjectDB', { id: this.id, data: this.config });
         
            let m={ message: 'refresh'};
            this.$store.commit('BROADCAST_MESSAGE', m);



            // if (data.component.oldid) {
            //     index = tabs.findIndex(cell => cell.id === data.component.oldid);
                
            //     component = {
            //         id: component.id,
            //         type: component.type,
            //         name: tabs[index].name
            //     }
            //     console.log("component appena creato  ", component);
            //     tabs.splice(index, 1, component);
                
            //     delete component.oldid;
                
            // } else 
            // console.log("old id not found");
            
            // Add the new component to the cells cells.push(component);  Add the new
            // component at the end Prepare props for the store update
            // const props = {
            //     data: {
            //         tabs,
            //         active_tab: this.config.active_tab,
            //         default_active_tab: this.config.default_active_tab
                    
            //     },
            //     notes: savedNotes
                
            // };
            // console.log("Updated container with new component", props);
            
            // Dispatch actions to update the store and fetch the new config
            // await this.$store.dispatch('setObjectDB', {
            //     id: data.component.id
            //     data: this.config
            // });
            // this.$store.commit('SET_CONFIG_OBJECT', { id: this.id, type: "bulmaSubTab", data: this.config });
            
            
        },
        
       
        updateConfig: function (name, val) {
            var c = this.config;
            c[name] = val;
            var payload = {
                id: this.id,
                cfg: {
                    id: this.id,
                    type: this.type,
                    data: c
                }
            }
            this.$store.commit('SET_CONFIG', payload);
        },
        tabClick: function (tabindex) {
            var t = this.config.tabs[tabindex];
            if(!t.disabled) this.updateConfig("active_tab", t.name);
         
        },
        hasConfig() {
            console.log("hasConfig")
            //return this.config && 'actions' in this.config;
            if(this.config){
                console.log("true")
                return true
            }
            return false;
        },
        isAdmin(){
            return this.$store.getters.isAdmin;
        },
    }
}
</script>

<style scoped="scoped">
.disabled {
    pointer-events:none;
    opacity:0.6;
    background-color:var(--bulma-tabs-boxed-link-active-border-color);
    border-start-end-radius: var(--bulma-tabs-boxed-link-radius);
    border-start-start-radius: var(--bulma-tabs-boxed-link-radius);
}
</style>