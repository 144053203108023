<template lang="html">

  <div class="field is-horizontal" v-bind:id="id">
    <div class="field-label is-small pl-1">
      <label class="label">{{ data.label }}</label>
    </div>
    <div class="field-body is-block">

      <div v-if="isActionable(data.type)">
        <a v-if="state == 'READY'" :href="filter( fieldval, data.type )">{{ fieldval }}</a>
        <input v-if="state != 'READY' || !fieldval" v-on:change="change" v-bind:readonly="readonly" v-bind:disabled="disabled" v-bind:value="fieldval" class="input" v-bind:type="data.type" v-bind:placeholder="data.placeholder" v-bind:class="{ 'border':disabled, 'is-success': data.success, 'is-danger': emptyField}">
      </div>

      <div class="field" v-if="isText(data.type)">
        <div class="control is-small">

          <div v-if="data.action" class="is-flex">
            <input class="input is-small is-rounded" v-model="inputField" v-bind:type="data.type" v-bind:placeholder="data.placeholder">
            <i class="fas fa-lg fa-circle-arrow-right is-align-content-center ml-2" @click="search()"></i>
          </div>

          <div v-if="!data.action">
            <input v-if="data.type!='textarea'" v-on:change="change" v-bind:readonly="readonly" v-bind:disabled="disabled" v-bind:value="fieldval" class="input" v-bind:type="data.type" v-bind:placeholder="data.placeholder" v-bind:class="{ 'border':disabled, 'is-success': data.success, 'is-danger': emptyField}">
            <!-- <textarea v-if="data.type!='textarea'" @change="change" :readonly="readonly" :disabled="disabled" :value="fieldval" class="textarea" :type="data.type" :placeholder="data.placeholder" :class="{ 'border':disabled, 'is-success': data.success, 'is-danger': emptyField}"></textarea> -->
            <!-- <input v-model="inputField" v-bind:readonly="readonly" v-bind:disabled="disabled" v-bind:value="fieldval" v-if="data.type!='textarea'" class="input" v-bind:type="data.type" v-bind:placeholder="data.placeholder" v-bind:class="{ 'is-success': data.success, 'is-danger': emptyField}"> -->
            <textarea id="textarea" v-if="data.type=='textarea'" :rows="textareaRows" v-on:change="change" v-bind:readonly="readonly" v-bind:disabled="disabled" v-bind:value="fieldval" class="textarea" :type="data.type" v-bind:placeholder="data.placeholder" v-bind:class="{'border':disabled, 'is-success': data.success, 'is-danger': emptyField}"></textarea>
            <p v-if="data.success || emptyField" class="help" v-bind:class="{ 'is-success': data.success, 'is-danger': emptyField}">{{ data.message }}</p>
          </div>
        </div>
      </div>

      <div v-if="data.type=='currency' || data.type=='number'">  
        <input v-on:change="change" v-bind:readonly="readonly" v-bind:disabled="disabled" v-bind:value="filter(fieldval, data.type)" class="input" v-bind:type="data.type" v-bind:placeholder="data.placeholder" v-bind:class="{ 'border':disabled, 'is-success': data.success, 'is-danger': emptyField}">
      </div>

      <label v-if="data.type=='checkbox'" class="checkbox" >
        <input v-on:change="change" v-bind:readonly="readonly" v-bind:disabled="disabled" v-bind:value="fieldval" v-bind:type="data.type" :class="{ 'border':disabled, 'is-success': data.success, 'is-danger': emptyField}">
      </label>          

      <div v-if="data.type=='link'">
        <a v-bind:href="fieldval" target="_blank">{{fieldval}}</a>
      </div>

      <div v-if="data.type=='address'">
        <a :href="['https://maps.apple.com/?q=' + getAddress(fieldval)]" target="_blank">
          {{ getAddress(fieldval) }}
        </a>
      </div>

      <div v-if="data.type=='date'">
        <input type="date" v-on:change="change" v-bind:readonly="readonly" v-bind:disabled="disabled" v-bind:value="fieldval" class="input" v-bind:placeholder="data.placeholder" v-bind:class="{ 'border':disabled, 'is-success': data.success, 'is-danger': emptyField}">
      </div>

      <div v-if="data.type=='datetime'">
        <input type="datetime" v-on:change="change" v-bind:readonly="readonly" v-bind:disabled="disabled" v-bind:value="filter(fieldval, data.type)" class="input" v-bind:placeholder="data.placeholder" v-bind:class="{ 'border':disabled, 'is-success': data.success, 'is-danger': data.fail}">
      </div>

      <div v-if="data.type=='radio'" class="control">
        <label  class="radio" v-for="option in data.options" v-bind:key="option.value">
          <input v-on:change="change" v-bind:readonly="readonly" v-bind:disabled="disabled" v-bind:value="fieldval" v-bind:type="data.type" v-bind:name="data.name" >
          {{ option.label }}
        </label>
      </div>

      <div v-if="data.type=='picklist'" class="select is-fullwidth" style="height:auto" v-bind:class="{ 'border':disabled, 'is-success': data.success, 'is-danger': emptyField}">
        <select v-on:change="change" v-bind:readonly="readonly" v-bind:disabled="disabled" v-bind:value="fieldval">
          <option v-if="searchableField" value="" selected></option>
          <option v-for="option in picklistOptions" v-bind:key="option.value">{{ option.label }}</option>
        </select>
      </div>

      <div v-if="data.type=='lookup'">
        <span v-if="state == 'READY'">
          <a v-if="hasLinkPath()" @click="select()">{{ fieldval }}</a>
          <p v-else>{{ fieldval }}</p>
        </span>
        <div v-if="state != 'READY' || !fieldval" class="field has-addons" :class="{ 'pe-none':disabled }" @click="searchLookup()">
          <p class="control is-expanded">
            
            <input v-on:change="change" v-bind:readonly="true" v-bind:disabled="disabled" v-bind:value="fieldval" class="input" v-bind:type="data.type" v-bind:placeholder="data.placeholder" v-bind:class="{ 'border':disabled, 'is-success': data.success, 'is-danger': emptyField}">

          </p>
          <p class="control">
            <button class="button is-inverted" v-bind:disabled="disabled">
              <span class="icon"><i class="fa fa-search"></i></span>
            </button>
          </p>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
/*
'text' 'truncate' 'string' 'picklist'
'number' 'currency' 'decimal' 'date' 'datetime'
'checkbox' 'picklist'
*/

import numeral from 'numeral'
import moment from 'moment'

export default {
  name: 'bulmaField',
  components: {},
  props: {
    id: String,
    data: Object,
    disabledProp: Boolean,
  },
  data: function () {
    return {
      inputField: '',
      textareaRows: 0,
    }
  },
  watch: {
    inputField (input) {
      console.log('inputField: '+input, typeof(input))
    },
  },
  computed: {
    fieldval: function() {
      let r=this.$store.getters.getRecordData;
      let result=this.data.default;

      if (r) {
        if (this.data.type=='lookup') {
          result=this.getFieldValue(r, this.data.related);
        } else {
          result=this.getFieldValue(r, this.data.name);
        }
      }
      if(this.searchableField) {
        return this.$store.getters.getadvancedFilteringProperties(this.data.name)
      }
      return result;
    },
    disabled: function() {
      if(this.disabledProp) return true
      if(this.data.required && this.$store.getters.getRecordState == "NEW") return false
      if(this.searchableField) return false
      return (this.$store.getters.getRecordState!="EDIT" && this.$store.getters.getRecordState!="NEW") || this.data.readonly || this.data.disabled;
    },
    readonly: function() {
      if(this.searchableField) return false
      if (this.data.readonly) return true;
      return false;
    },
    emptyField() {
      // if(this.inputField != '') return false
      if( !this.fieldval && this.data.required) return true
      return false
    },
    searchableField() {
      return this.$store.getters.getRecordState == "SEARCH" && this.data.searchable
    },
    state() {
      return this.$store.getters.getRecordState
    },
    picklistOptions() {
      var options = []
      var activeFieldval = false
      this.data.options.forEach( o => {
        options.push(o)
        if(o.value == this.fieldval) activeFieldval = true
      })
      if(!activeFieldval) options.push({ "label": this.fieldval, "value": this.fieldval})
      return options
    }
  },
  mounted: function() {
    console.log()

    // if(this.data.type == 'textarea') this.resizeTextArea()

  },
  filters: {
  },
  methods: {
    /*
    resizeTextArea() {
      var textarea = document.getElementById('textarea')
      console.log('textarea', textarea)
      const lineHeight = parseInt(window.getComputedStyle(textarea).lineHeight);
      const lines = Math.floor(textarea.scrollHeight / lineHeight);
      console.log('resizeTextArea:', lines)
      this.textareaRows = lines
    },
    */
    change: function(e) {
      console.log("change",e.target.value);
      if(this.$store.getters.getRecordData) this.$store.commit('SET_FIELD_VALUE', { fieldname: this.data.name, fieldvalue: e.target.value} );

      if(this.data.required){
        if(!this.fieldval || (this.fieldval && this.$store.state.emptyRequiredFields)) {
          this.$store.dispatch('checkRequiredFields')
        }
      }

      if(this.searchableField) {
        if(e.target.value != '') {
          this.$store.commit('SET_ADVANCEDFILTERINGPROPERTIES',
            { 
              fieldname: this.data.name, 
              operator: this.$store.getters.getQueryOperator(this.data.type),
              value: e.target.value,
              table: this.data.table
            }
          )
        } else this.$store.commit('REMOVE_ONE_ADVANCEDFILTERINGPROPERTIES', { fieldname: this.data.name })
      }

      if(this.data.convertLead) this.$emit('saveNewRecord')

      /*
      if (!this.$store.getters.getProcessDepth) {
        console.log('quick save:', this.$store.getProcessDepth);

        let m={ message: 'quick_save'};
        this.$store.commit('POST_MESSAGE', m);
      } else {
        console.log('no quick save:', this.$store.getProcessDepth);
      }
      */

    },
    search() {
      console.log('search this.data.action', this.data.action)
      let m={ message: this.data.action, payload: this.inputField};
      this.$store.commit('POST_MESSAGE', m);
    },
    isText: function(type) {
      if (type=='text' || type=='password' || type=='tel' || type=='textarea') return true;
      return false
    },
    isActionable: function(type) {
      if (type=='email' || type=='phone') return true;
      return false;
    },
    filter: function(v, type) {
      switch(type) {
        case 'hidden':
          return;
        case 'date':
          if (v) {
            var n=new moment(v);
            return n.format('MM/DD/YYYY');
          }
          return v;
        case 'datetime':
          if (v) {
            var n=new moment(v);
            return n.format('MM/DD/YYYY HH:mm');
          }
          return v;
        case 'decimal':
          if (v)
            return numeral(v).format('0,0');
          return v;
        case 'currency':
          if (v)
            return numeral(v).format('$ 0,0.00');
          return v;
        case 'truncate':
          if (v)
            return v.substr(0,32);
        case 'phone':
          if(v) return 'tel:'+v;
        case 'email':
          if(v) return 'mailto:'+v;
      }
      return v;
    },
    getAddress(value) {
      if(!value) return ''
      
      var street = ''
      if(value.street) street = value.street
      if(value.city) street += ' '+value.city
      if(value.state) street += ' '+value.state
      if(value.postalCode) street += ' '+value.postalCode

      return street

    },
    select: function() {
      var id = this.$store.getters.getRecordData[this.data.name]
      var path = this.getLinkPath(id)
      this.$router.push(`/${path}/d/${id}`);
    },
    getLinkPath(id) {
      if(!id) return false
      var idStart = id.slice(0,3)
      var process = this.$store.state.config.processes.find( obj => obj.recordStartWith == idStart )
      if(process) return process.id
      return false
    },
    hasLinkPath() {
      var id = this.$store.getters.getRecordData[this.data.name]
      if(this.getLinkPath(id)) return true
      return false
    },
    getFieldValue: function(obj, path) {
      // eslint-disable-next-line no-redeclare
      for (var i=0, path=path.split('.'), len=path.length; i<len; i++){
        if (obj)
          obj = obj[path[i]];
      }
      return obj;
    },
    align: function(type) {
      switch(type) {
        case 'currency':
        case 'decimal':
          return 'right';
      }
      return 'left';
    },
    searchLookup () {
      console.log('searchLookup')

      /*
      this.$store.commit( 'SET_LOOKUP_FIELD', this.data.name );
      this.$store.commit( 'SET_LOOKUP_VALUE', this.fieldval );

      var subProcessPage = this.$store.getters.getPage(this.data.process)
      var object = this.$store.getters.getObject(subProcessPage.initialid)
      var data = {
        id: subProcessPage.initialid,
        type: object.type,
        title: object.data.title,
      }
        */
      // this.$store.commit('POST_MESSAGE', {message: 'goto_search', payload: data} );

      var data = {
        processId: this.data.process,
        verbId: this.data.verb,
        title: this.data.label,
        fieldname: this.data.name,
        related_fieldname: this.data.related,
        fieldval: this.fieldval,
        defaults: this.data.defaults
      }

      var verb = this.$store.getters.getVerb( this.$store.getters.getProcess(this.data.process), this.data.verb )
      this.$store.commit('SET_RECORDS_STATE', {id: this.data.process, state: verb.state});

      this.$store.commit('PUSH_PROCESS_STATUS', data);
    },
  }
}
</script>

<style scoped>
.border {
  border: 1px solid;
  border-radius: 0.375rem;
}
.pe-none {
    pointer-events: none;
}
</style>
