<template lang="html">
  <!-- Pulsante per aprire il modale -->
  <div v-if="hasConfig()">
    <bulmaFieldSet2Props v-if="showModal" 
      v-bind:id="id" 
      v-bind:config="config" 
      v-on:close="closeModal"
    ></bulmaFieldSet2Props>

    

  <div class="columns is-multiline is-1" v-bind:id="id" >
    <div class="column" v-bind:class="{'is-half':config.dual,'is-full':!config.dual}" v-for="(field, index) in fields" v-bind:key="field.name">
      <bulmaField v-if="field.name" v-bind:id="field.name" v-bind:data="field" v-bind:table="config.table" v-bind:readonly="field.readonly"></bulmaField>

      <div class="field is-horizontal" v-bind:id="id" v-if="field.blank">
        <div class="field-label is-small">
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control is-small">
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal" v-bind:id="id" v-if="field.isGoogleAutocomplete && !disabled">
        <div class="field-label is-small">
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control is-small" v-bind:id="id+'isGoogleAutocomplete'">
            </div>
          </div>
        </div>
      </div>

    </div>
    

     <div class="columns">
      <div class="column is-12">
        <button class="button has-addons" v-on:click="showModal = true" v-if="getEditProps">
          <span class="icon is-small">
            <i class="fas fa-pen"></i>
          </span>
          <span>Field Set</span>
        </button>
      </div>
     </div>
     
          
      <!-- <div >
        <button class="has-background-light" v-on:click="showGossipTest=!showGossipTest" >test comunicazione backend</button>
      </div>
      <backend v-if="showGossipTest"></backend> -->
  </div></div>
  <div v-else-if="isAdmin && getEditProps">
    <button class="button" v-on:click="showModal=!showModal">inisialise fieldset</button>
    <bulmaFieldSet2Props v-if="showModal" 
      v-bind:id="id" 
      v-bind:config="config" 
      v-on:close="closeModal"
    ></bulmaFieldSet2Props>
  </div>
  
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader"
import { readonly } from "vue";
import bulmaFieldSet2Props from './bulma_fieldset2props.vue';
// import backend from './backendComunication.vue';
export default {
  name: 'bulmaFieldSet2',
  components: {
    bulmaFieldSet2Props,
    // backend
  },
  props: {
    id: String,
    recordid: String,
  },
  data: function () {
    return {
      showModal: false,
      place: {}
    }
  },
  watch: {
    disabled: function(newVal) {
      if (!newVal) {
        if (this.config && this.config.apiKey) this.googleAutocomplete();
      }
    }
  },
  computed: {
    invalid: function() {
      let invalid=false;
      let r=this.$store.getters.getRecordData;
      this.fields.forEach( (f) => {
        let fval=this.getFieldValue(r, f.name);
        if (f.required && !fval) invalid=true;
      })
      return invalid;
    },
    getEditProps(){
      return this.$store.getters.getEditProps;
    },
    fields: function () {
      if (!this.$store.state.database.has(this.config.table)) return [];
      let table=this.$store.state.database.get(this.config.table);
      let solvedfields=[]
      this.config.fields.forEach( f => {
        if (f.isBlank || f.isGoogleAutocomplete) {
          solvedfields.push((f));
        }
        if (table.has(f.fieldname)) {
          if(table.get(f.fieldname).type != 'hidden') {
            let f2={ ...table.get(f.fieldname) }
            if (f.readonly) f2.readonly=f.readonly;
            solvedfields.push( f2 );
          }
        }
      });
      return solvedfields;
    },
    config: function () {
      if (this.$store.getters.getObject(this.id)){
        return this.$store.getters.getObject(this.id).data;
      }else {
        return {}
      }
    },
    googleField: function() {
      return this.config.fields.find( (v) => { if (v.isGoogleAutocomplete) return true; } );
    },
    disabled: function() {
      return this.$store.getters.getRecordState!="EDIT" && this.$store.getters.getRecordState!="NEW";
    },
  },
  mounted: function() {
    this.$nextTick( () => {
      this.setDefaults();
      if (this.config && this.config.apiKey) this.googleAutocomplete();

    })
    /*
    setTimeout( () => {
      console.log("delayed defaults!!!");
      this.setDefaults();
    }, 2000);
    */
  },
  filters: {
  },
  methods: {
    setDefaults: function() {
      var defaults = []

      let s=this.$store.getters.getProcessStatus;
      if (s && s.defaults) {
        defaults = s.defaults
      } else if(this.$store.state.global_state_defaults.length > 0) {
        defaults = this.$store.state.global_state_defaults
      }
      console.log('getProcessStatus for defaults', defaults);

      if(defaults.length > 0) {
        defaults.forEach( (d) => {
          console.log("default", d);
          let dval=''
          if (d.default) dval=d.default
          if (d.previous) {
            let s = this.$store.state.global_state_process;            
            let r=this.$store.state.records_data[s]        
            
            dval= r[d.previous];
          }
          this.$store.commit('SET_FIELD_VALUE', { fieldname: d.fieldname, fieldvalue: dval } );
        })
      }
    },
    getFieldValue: function(obj, path) {
      // eslint-disable-next-line no-redeclare
      for (var i=0, path=path.split('.'), len=path.length; i<len; i++){
        if (obj)
          obj = obj[path[i]];
      }
      return obj;
    },
    googleAutocomplete: async function() {
      console.log("--> googleAutocomplete")
      console.log('apikey', this.config.apiKey);
      this.loader = await this.$store.dispatch( 'googleMapLoader', { loader: Loader, apiKey: this.config.apiKey } )
      console.log('loader f2!', this.loader);

      this.loader.load().then(async () => {
          // eslint-disable-next-line no-undef
          await google.maps.importLibrary("marker");
          // eslint-disable-next-line no-undef
          await google.maps.importLibrary("places");

          console.log('google.maps.places', google.maps.places);
          
          // eslint-disable-next-line no-undef
          const placeAutocomplete = new google.maps.places.PlaceAutocompleteElement();
          placeAutocomplete.id = "place-autocomplete-input";
          const card = document.getElementById(this.id+'isGoogleAutocomplete');
          card.appendChild(placeAutocomplete);

          placeAutocomplete.addEventListener("gmp-placeselect", async ({ place }) => {
            await place.fetchFields({
              fields: ["displayName", "formattedAddress", "location", "addressComponents"],
            });
            /*
              civico street_number
              strada  route
              blocco locality
              città administrative_area_level_3
              contea administrative_area_level_2
              stato administrative_area_level_1
              country country
              zip postal_code
            */
            // select name, Geolocation__Latitude__s, Geolocation__Longitude__s, distance(Geolocation__c, GEOLOCATION(44.6417923 , 10.9422918), 'km') from Opportunity where distance(Geolocation__c, GEOLOCATION(44.6417923 , 10.9422918), 'km')<5
            let gf=this.googleField;
            if (gf) {

              for (let f in gf) {
                if (f!='isGoogleAutocomplete' && gf[f]) {
                  this.$store.commit('SET_FIELD_VALUE', { fieldname: gf[f], fieldvalue: '' } );
                }
              }
              place.addressComponents.forEach( (c) => {
                if (c.types && c.types.length>0 && gf[c.types[0]]) {
                  this.$store.commit('SET_FIELD_VALUE', { fieldname: gf[c.types[0]], fieldvalue: c.longText } );
                } else console.log('Campo non salvato!!!', c.longText, 'type: '+c.types[0] )
              });
              if (gf['latitude'])   this.$store.commit('SET_FIELD_VALUE', { fieldname: gf['latitude'], fieldvalue: place.location.lat() } );
              if (gf['longitude'])  this.$store.commit('SET_FIELD_VALUE', { fieldname: gf['longitude'], fieldvalue: place.location.lng() } );

              this.place=place;
            }
          });
      });
    },
    hasConfig() {
      if( this.config) return true;
      return false
    },
    isAdmin(){
      return this.$store.getters.isAdmin;
    },
   
    
    closeModal() {
      this.showModal = false;
    }
  }
}
</script>

<style scoped></style>
