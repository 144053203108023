export default {
    // nome app
    "SalesPaddy":"SalesPaddy",
    // tabs
    "Home":"Home",
    "Account":"Clienti",
    // filtro nelle tabelle
    "Applica filtro":"Applica filtro",
    "filtro...":"filtro...",
    // azioni salon
    "Crea":"Crea",
    // salons: tabella e form
    "Ragione Sociale":"Ragione Sociale",
    "Insegna":"Insegna",
    "Autocomplete":"Completamento automatico",
    "Indirizzo":"Indirizzo",
    "Città":"Città",
    "Cap":"Cap",
    "Paese":"Paese",
    "Provincia":"Provincia",
    "Nazione":"Nazione",
    "Email":"Email",
    "Telefono":"Telefono",
    "Web":"Web",
    "Attivo":"Attivo",
    "Alert":"Alert",
    "Tipo":"Tipo",
    "Latitude":"Latitude",
    "Longitude":"Longitude",
    "Latitude2":"Latitude2",
    "Longitude2":"Longitude2",
    // sezioni form
    "Dati principali":"Dati principali",
    "Sede Legale":"Sede Operativa",
    "Contatto":"Contatto",
    "Dati qualitativi":"Dati qualitativi",
    // sezioni form
    "Dati Anagrafici":"Dati Anagrafici",
    // mappa
    "Mappa Saloni":"Mappa Saloni",
    "Saloni visualizzati:":"Saloni visualizzati:",
    "Leads":"Leads",
    "Saloni":"Saloni",
    "Tipo Visualizzazione:":"Tipo Visualizzazione:",
    "Heatmap":"Heatmap",
    "Markers":"Markers",
    "Scheda Lead":"Scheda Lead",
    "Scheda Cliente":"Scheda Cliente",
    "Google Maps":"Google Maps",
    "Risultato ricerca:":"Risultato ricerca:",
    "Corrispondenze trovate:":"Corrispondenze trovate:",
    "Valutazione:":"Valutazione:",
    "Recensioni:":"Recensioni:",
    "x":"x",

    "ScegliFileTitolo": "Scegli un file",
    "ScegliFilePulsante": "Scegli",
    "DimensioniFile": "Dimensione:"
}