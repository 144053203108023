import axios from 'axios'
import jwt from 'jsonwebtoken'

export default {

  async login(email, myid_token ) {
    console.log('apiclient login',email, myid_token);
    
    try {
      var mytokenizedid = jwt.sign({ email: email, private_id: myid_token }, this.key, { expiresIn: "30s", algorithm: 'RS256'});
      console.log(mytokenizedid)
    } catch(err) {
      console.log(err);
      return;      
    }

    let resp = await axios.post('/me', { token: mytokenizedid })
    var data = resp.data;
    console.log(data);
    if (data.token) {
      var o = {
        token: data.token, // token del server
        userdata: data.userdata // dati utente
      }
      return o;
    }
    return data;
  },

  async ssologin() {
    console.log('apiclient ssologin');
    
    try {
      var mytokenizedid = jwt.sign( { retUrl: window.location.origin }, this.key, { expiresIn: "180s", algorithm: 'RS256'});
      console.log(mytokenizedid)
    } catch(err) {
      console.log(err);
      return;
    }

    let resp = await axios.post('/ssologin', { token: mytokenizedid })
    console.log('after post:', resp);
    
    return resp;
  },

  async myprofile(mytoken) {
    console.log('apiclient myprofile');
    var url = "./myprofile";

    var payload = {
      token: mytoken,
    }
    var jwtpayload = jwt.sign( payload, this.secret );

    let resp=await axios.post(url, { payload: jwtpayload } );    
    console.log('after post:', resp.data);
    
    return resp.data;
  },

  async validate(mytoken) {
    console.log('manda la validate');
    var url = "./validate";
    var payload = {
      token: mytoken
    }

    // if(tokenOnBealfOf) payload['tokenOnBealfOf']

    var jwtpayload = jwt.sign( payload, this.secret );

    let resp=await axios.post(url, { payload: jwtpayload } );
    console.log('ricevuto');
    return resp.data;
  },

  async getmeta(mytoken) {
    console.log('manda getmeta');
    var url = "./getmeta";
    var payload = {
      token: mytoken,
    }
    console.log('getmeta',payload);

    var jwtpayload = jwt.sign( payload, this.secret );

    let resp=await axios.post(url, { payload: jwtpayload } );
    console.log('ricevuto', resp.data.data.value);
    return resp.data.data.value;
  },

  async setmeta(mytoken, config) {
    console.log('manda setmeta');
    var url = "./setmeta";
    var payload = {
      token: mytoken,
      data: config
    }
    console.log('setmeta',payload);

    var jwtpayload = jwt.sign( payload, this.secret );

    let resp=await axios.post(url, { payload: jwtpayload } );
    console.log('ricevuto');
    return resp.data.value;
  },

  async query(mytoken, soql ) {
    console.log('manda la query');
    var url = "./query";
    var payload = {
      token: mytoken,
      soql: soql,
    }
    console.log('apiclient query payload',payload);
    var jwtpayload = jwt.sign( payload, this.secret );

    let resp=await axios.post(url, { payload: jwtpayload } )

    console.log('ricevuto');
    return resp.data;
  },

  async search(mytoken, object, properties, filters, orders, groups, offset, limit, childQuery ) {
    console.log('manda la search');
    var url = "./search";
    var payload = {
      token: mytoken,
      object: object,
      properties: properties,
      filters: filters,
      orders: orders,
      groups: groups,
      offset: offset, 
      limit: limit,
      childQuery: childQuery
    }
    console.log('apiclient search payload',payload);
    if (payload.filters) {
      payload.filters.forEach( f => {
        f.propertyName=f.fieldname;
      });
    }
    if (payload.orders) {
      payload.orders.forEach( f => {
        f.propertyName=f.fieldname;
      });
    }

    var jwtpayload = jwt.sign( payload, this.secret );

    let resp=await axios.post(url, { payload: jwtpayload } )

    console.log('ricevuto');
    return resp.data;
  },

  async locate(mytoken, querystring) {
    console.log('manda la locate');
    var url = "./locate";

    var sosl = "FIND {"+querystring+"} RETURNING Lead (id, Name, Company), Account (id, Name, Type), Opportunity (id, Name, Type, StageName), Contact (id, Name, Account.Name), User (id, Name), Product2 (id, Name), Project_player__c (id, Name, Account__r.Name, Contact__r.Name)";

    var payload = {
      token: mytoken,
      querystring: sosl,
    }
    console.log('locate',payload);

    var jwtpayload = jwt.sign( payload, this.secret );

    let resp=await axios.post(url, { payload: jwtpayload } )
    .catch(function (error) {
      console.log('apiclient.locate error', error.length, error)
      if (error.response) {
        return error.response
      } else if (error.request) console.log(error.request);
      else console.log('Error', error.message);
    })
    return resp.data;
  },

  async create(mytoken, object, properties) {
    console.log('manda la create');
    var url = "./create";

    let data={};
    for (let p in properties) {
      if (p!='Id' && p!='attributes') data[p]=properties[p];
    }

    var payload = {
      token: mytoken,
      object: object,
      recordData: data,
    }
    console.log('create',payload);

    var jwtpayload = jwt.sign( payload, this.secret );

    let resp=await axios.post(url, { payload: jwtpayload } )
    .catch(function (error) {
      console.log('apiclient.create error', error.length, error)
      if (error.response) {
        return error.response
      } else if (error.request) console.log(error.request);
      else console.log('Error', error.message);
    })

    // console.log('ricevuto', resp.data);
    // console.log('ricevuto2', resp.data.data);
    return resp.data;
  },

  async retrieve(mytoken, object, recordId, properties, associations) {
    console.log('manda la retrieve');
    var url = "./retrieve";
    var payload = {
      token: mytoken,
      object: object,
      recordId: recordId,
      properties: properties,
      associations: associations
    }
    console.log('retrieve',payload);

    var jwtpayload = jwt.sign( payload, this.secret );

    let resp=await axios.post(url, { payload: jwtpayload } )
    .catch(function (error) {
      console.log('apiclient.retrieve error', error.length, error)
      if (error.response) {
        return error.response
      } else if (error.request) console.log(error.request);
      else console.log('Error', error.message);
    })

    // console.log('ricevuto', resp.data);
    // console.log('ricevuto2', resp.data.data);
    return resp.data;
  },


  async update(mytoken, object, recordId, properties) {
    console.log('manda la update');
    var url = "./update";

    let data={};
    for (let p in properties) {
      if (p!='Id' && p!='attributes' && typeof properties[p] != 'object') data[p]=properties[p];
    }

    var payload = {
      token: mytoken,
      object: object,
      recordId: recordId,
      recordData: data,
    }
    console.log('update',payload);

    var jwtpayload = jwt.sign( payload, this.secret );

    let resp=await axios.post(url, { payload: jwtpayload } )
    .catch(function (error) {
      console.log('apiclient.update error', error.length, error)
      if (error.response) {
        return error.response
      } else if (error.request) console.log(error.request);
      else console.log('Error', error.message);
    })
    // console.log('ricevuto', resp.data);
    // console.log('ricevuto2', resp.data.data);
    
    return resp.data;
  },

  async delete(mytoken, object, recordId, microsoftId) {
    console.log('manda la delete');
    var url = "./delete";

    var payload = {
      token: mytoken,
      object: object,
      recordId: recordId,
      microsoftId: microsoftId
    }
    console.log('delete',payload);

    var jwtpayload = jwt.sign( payload, this.secret );

    // const response = await axios.delete(url, config);
    let resp=await axios.post(url, { payload: jwtpayload } )
    .catch(function (error) {
      console.log('apiclient.delete error', error.length, error)
      if (error.response) {
        return error.response
      } else if (error.request) console.log(error.request);
      else console.log('Error', error.message);
    })
    // console.log('ricevuto', resp.data);
    // console.log('ricevuto2', resp.data.data);
    
    return resp.data;
  },
 
  async batchread(mytoken, object, recordIds, properties) {
    console.log('manda la search');
    var url = "./batchread";
    var payload = {
      token: mytoken,
      object: object,
      recordIds: recordIds,
      properties: properties
    }
    console.log('batchread',payload);

    var jwtpayload = jwt.sign( payload, this.secret );

    let resp=await axios.post(url, { payload: jwtpayload } );
    console.log('ricevuto');
    return resp.data;
  },

  // apiclient.js
  async fetchLocations() {
    try {
      const response = await fetch('/locations');
  
      if (response.ok) {
        const locationsData = await response.json();  // Parse JSON response
        console.log('Locations Data:', locationsData);  // Log the data for debugging
        return locationsData;  // Return the parsed data
      } else {
        throw new Error(`Error fetching locations: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error:', error);
      return [];  // Return an empty array in case of error
    }
  },
  async updateGlobal(locationName, profile, websiteUri) {
    try {
      const response = await fetch('/update-global', {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          locationName,
          profile,  
          websiteUri
        })
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error response from server:', errorData);
        throw new Error(errorData.message || 'Error updating global profile.');
      }
  
      const data = await response.json();
      console.log('Global profile update successful:', data);
      return data;
  
    } catch (error) {
      console.error('Error updating global profile:', error.message);
      throw error;
    }
  },
  
 async updateLocation(locationName, updatedAddress) {

    try {
      const response = await fetch('/update-location', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          locationName,
          updatedAddress,
        }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error updating location.');
      }
  
      const data = await response.json();
      console.log('Location update successful:', data);
      return data;
  
    } catch (error) {
      console.error('Error updating location:', error);
      throw error;
    }
  },
   async updateHours(locationName, editedHours) {

    try {
      const response = await fetch('/update-hours', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          locationName,
          updatedHours: editedHours,
        }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error updating hours.');
      }
  
      const data = await response.json();
      console.log('Hours update successful:', data);
      return data;
  
    } catch (error) {
      console.error('Error updating hours:', error);
      throw error;
    }
  },
  async createcustomevent(mytoken, timestamp, eventTemplateId, objectId, tokens) {
    console.log('manda la search');
    var url = "./createcustomevent";
    var payload = {
      token: mytoken,
      timestamp: timestamp,
      eventTemplateId: eventTemplateId,
      objectId: objectId,
      tokens: tokens,
    }
    console.log('createcustomevent',payload);

    var jwtpayload = jwt.sign( payload, this.secret );

    let resp=await axios.post(url, { payload: jwtpayload } );
    console.log('ricevuto');
    return resp.data;
  },
  async getConfig(mytoken) {
    console.log('apiclient getConfig entered');
    
    try {
       
        const payload = { token: mytoken };
        const jwtpayload = jwt.sign(payload, this.secret);
        
     
        const urls = {
            processes: "./getProcess",
            pages: "./getPages",
            components: "./getComponents",
            database: "./getDBStructure"
        };

        // await delle chiamate al backend
        const [processesResponse, pagesResponse, componentsResponse, databaseResponse] = await Promise.all([
            axios.post(urls.processes, { payload: jwtpayload }),
            axios.post(urls.pages, { payload: jwtpayload }),
            axios.post(urls.components, { payload: jwtpayload }),
            axios.post(urls.database, { payload: jwtpayload })
        ]);

        // converto risposte in dati per la configurazione locale
        const processes = processesResponse.data.map(record => ({
            id: record.id__c,
            table: record.table__c,
            verbs: JSON.parse(record.verbs__c),
            recordStartWith: record.Record_Start_With__c
        }));
        console.log(processes, " proccessi");

        const pages = pagesResponse.data.map(record => ({
            id: record.id__c,
            initialid: record.initialid__c,
            process: record.process__c
        }));
        console.log(pages, " pages");

        const components = componentsResponse.data.map(record => ({
            id: record.id__c,
            type: record.type__c,
            note: record.notes__c,
            data: JSON.parse(record.data__c)
        }));
        console.log(components, " components");
        
        const database = databaseResponse.data.map(record => ({
            fields: JSON.parse(record.fields__c),
            table: record.table__c
        }));
        console.log(database, " database")

        // data da restituire
        const data = {
            processes: processes,
            pages: pages,
            objects: components,
            database: database
        };
        
        return data;

    } catch (error) {
        console.error('Error when loading configuration from DB:', error); 
    }
  },
  async setObject(mytoken, toUpdate) {
    try {
      const url = "./setComponent";
      console.log("to update: ", toUpdate);
      // const obj = {
      //   id__c: toUpdate.id,
      //   data__c: JSON.stringify(toUpdate.data, null, 2),
      //   notes__c: toUpdate.notes
      // };
      let obj={};
      if(toUpdate.id)obj.id__c=toUpdate.id;
      if(toUpdate.type)obj.type__c=toUpdate.type;
      if(toUpdate.data)obj.data__c=JSON.stringify(toUpdate.data, null, 2);
      if(toUpdate.notes)obj.notes__c=toUpdate.notes;
      const payload = {
        token: mytoken,
        obj: obj
      };
      const jwtpayload = jwt.sign(payload, this.secret);
  
      // Log del payload per debug
      console.log('setComponent payload:', payload.obj);
      const response = await axios.post(url, { payload: jwtpayload });
  
      console.log('(front)beckend reply:', response.data);
      return "all command computed by send object";
    } catch (error) {
      console.error('(front)Errore nella richiesta:', error);
      return("error in send object: ", error);
    }
    
  },
  async createObject(mytoken, toCreate) {
    try {
      const url = "./createComponent";
      console.log("to create: ", toCreate);
      const obj = {
        id__c: toCreate.id,
        type__c: toCreate.type
      };
      const payload = {
        token: mytoken,
        obj: obj
      };
      const jwtpayload = jwt.sign(payload, this.secret);
  
      // Log del payload per debug
      console.log('setComponent payload:', payload);
      const response = await axios.post(url, { payload: jwtpayload });
  
      console.log('(front)backend reply:', response.data);
      return "all command computed by send object";
    } catch (error) {
      console.error('(front)Errore nella richiesta:', error);
      return("error in send object: ", error);
    }
    
  },
  async toDelete(mytoken, id) {
    try {
      const url = "./deleteComponent";
      console.log("id component to delete ", id);
      const payload = {
        token: mytoken,
        id: id
      };
      const jwtpayload = jwt.sign(payload, this.secret);
  
      // Log del payload per debug
      console.log('toDelete payload:', payload);
      const response = await axios.post(url, { payload: jwtpayload });
  
      console.log('(front)backend reply:', response.data);
      return "all command computed by send object";
    } catch (error) {
      console.error('(front)Errore nella richiesta:', error);
      return("error in send object: ", error);
    }
    
  },
  async getObject(mytoken, id) {
    try {
      const url = "./getComponentConfig"; 
      const payload = { token: mytoken, id: id };
      const jwtpayload = jwt.sign(payload, this.secret);
  
      const response = await axios.post(url, { payload: jwtpayload });
      
      let config = {
        id: response.data.record.id__c,
        type: response.data.record.type__c,
        notes: response.data.record.notes__c,
        data: JSON.parse(response.data.record.data__c),

      }
      
      console.log('(front) Response from backend:', config);
      return config;
    } catch (error) {
      // Improved error logging for debugging
      if (error.response) {
        console.error('(front) Request failed with status:', error.response.status);
        console.error('(front) Response data:', error.response.data);
      } else {
        console.error('(front) Error in request:', error.message);
      }
      return "error in get object: " + error.message;
    }
  },
  async getHousings(mytoken) {
    console.log('apiclient getHousing entered');
    
    try {
       
        const payload = { token: mytoken };
        const jwtpayload = jwt.sign(payload, this.secret);
        
     
        const url = "./getHousings";
       
        const response = await axios.post(url, { payload: jwtpayload });

        // converto risposte in dati per la configurazione locale
        const objects = response.data.map(record => ({
            id: record.id__c,
            type: record.type__c,
            data: JSON.parse(record.data__c) || "",
            notes: record.notes__c || ""
        }));

        
        return objects;

    } catch (error) {
        console.error('Error when loading configuration from DB:', error); 
    }
  },
  
  async saveSubscription(mytoken, data) {
    //data.subscription
    /*
    * struttura di data:
    * data{
    *   subscription: {},
    *   ContactId__c: ""
    * } 
    */


    console.log('the subscription has been completed:', data.subscription);
    try {
      const url = "./save-subscription";
      const payload = {
        token: mytoken,
        data: data
      };
      const jwtpayload = jwt.sign(payload, this.secret);
  
      // Log del payload per debug
      //console.log('saveSubscription payload:', payload);
      const response = await axios.post(url, { payload: jwtpayload });
  
      console.log('(front)backend reply:', response.data);
      return "all command computed by saveSubscription";
    } catch (error) {
      console.error('(front)Request error:', error);
      return("error in send object: ", error);
    }
    
  },
  async generatePdf(payload) {
    console.log('manda la generate-pdf');
    var url = "./generate-pdf";

    // console.log('generate-pdf', payload, typeof(payload));

    let resp = await axios.post(url, { payload: payload } )
    .catch(function (error) {
      console.log('apiclient generate-pdf error', error.length, error)
      if (error.response) {
        return error.response
      } else if (error.request) console.log(error.request);
      else console.log('Error', error.message);
    })
    
    return resp;
  },

  async getNotification(mytoken, data){
    const payload = { token: mytoken, contactId: data.contactId };
    const jwtpayload = jwt.sign(payload, this.secret);
    
  
    const url = "./getNotifications";
    
    const response = await axios.post(url, { payload: jwtpayload });
    try {
      const toReturn = JSON.parse(response);
      return toReturn;
    } catch (error) {
      return response; // Non è un JSON 
    }
  },
  // Funzione per convertire la chiave VAPID in Uint8Array
  urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  },
 
  
  
  async getcontentbody(connection_id, uri){
    console.log('apiclient getcontentbody entered');
    let url = "./getContentBody";
    let payload = { connection_id: connection_id, uri: uri };
    console.log('getcontentbody payload', payload);
    let jwtpayload = jwt.sign(payload, this.secret);
    let resp = await axios.post(url, { payload: jwtpayload } )
    .catch(function (error) {
      console.log('apiclient getcontentbody error', error.length, error)
      if (error.response) {
        return error.response
      } else if (error.request) console.log(error.request);
      else console.log('Error', error.message);
    })
    return resp;
  },

  createcontentversion(connection_id, data){
    console.log('apiclient createcontentversion entered');
    let url = "./createContentVersion";
    let payload = { connection_id: connection_id, data: data };
    console.log('createcontentversion payload', payload);
    let jwtpayload = jwt.sign(payload, this.secret);
    let resp = axios.post(url, { payload: jwtpayload } )
    .catch(function (error) {
      console.log('apiclient createcontentversion error', error.length, error)
      if (error.response) {
        return error.response
      } else if (error.request) console.log(error.request);
      else console.log('Error', error.message);
    })
    return resp;
  },
  

  secret: 'e2ffccce2a69190b8f3f16896b2cadeae380caaaae7e7fac2f31a83bd55757c5',

  key:`-----BEGIN RSA PRIVATE KEY-----
MIIJKQIBAAKCAgEAseu2rOcqo+/jiEP2jJWn0z+rqm9/RFaLkVgwv4gnlx6/ujmB
LeoEgAm7iVF46QpZhrpq/yTrQhjOvzSXbqgt/aNdBBaIb11QFA0d8Wi6Cc15Hmx2
qMtqxj9wq6kBLSsAULv77a9V4YFr1N8Jqtm5WQwIwEVz2pMcpmbeDos4iE9FJmo3
aahkiBNve0qFfNGuz7+YRNh55Ixh1RUZFyRzfbzzaJRn08vFAl9HWFihYm+YCwx+
XoE2+D9k/ZObiA2vytpRGW6tfNO4HdVg2dGtXdvnyQkXI3KumYYUFwshcrpYizTH
le3W8eiRzUfgkaaQuJVqKdbK3AWRC7MfeKZWtTFBdam603VBZTvGLV1o560JQRO2
oEVH8BMhkypT46SCXojon2bQSaTrJriXZFz/+gI9QS2NnsdBeTqTaN/Oe2qF+XZU
evi9t3Bs+h3CF20IUf4f79XoHaGeSY7h9tsqR8p9WRMlSugmCta1FcEFq3mP7kol
0+L5SRDyOXFBeZ6wi8UQuSaw1JfG2TokmLzlfDHb8+ta4PkrzJMTs7DOKSD4ZJuG
Xr5XTCX9/VdElkOqDh197963jkCHEiIDG+iEhp/6mtOoQXF3GsDj4RDwpnegIzMc
bqLcSRTDxTGHe1aqxb9bAbf1bv6IUT8D8lVtmZVMNKKWyle9uvJ53cChzjUCAwEA
AQKCAgAUAsnh+oH+PD3gFpzFRRPCG7goDDBJNF6wMzYQZLTnN0sjG9KeomJtMCsK
j8JEm6hkEdV06ucW4Qln0fdyN7Isg9IzYoFn3RvjlXIXs8qwzY5g0Jwr8e0JbRs0
A2cheGOpsOrt2bmUCmVhZNCGicRh8ouNbIJSRBeF064H/rqWAixp3Kps1Max01XH
jSMkXcUGkqsCm0JiZTOfrqbKxovCyZyq134yAQogcSzqD9Xe52Q3YelYobnYA/FM
3+wvSjLKYvGaJauXjYx4toYVGZMhKlmQb9hNYVmn5L+fs93INJM+3OnRwBVb0eeM
Khhk6VH/oLZ3DDR2uJ2Ork/M1xCLUkFSnY5mKVYMyLzzABqTR0aP9ReJ0whp5rPj
nqjGES0gbom24oSFWTPGtZY/GhllngeZXxPffS5kXChkAdwZNi9iE1CSkeiXFjoq
2cCb4v4UWGCamUEE8HgyYbDruZyX4HZgj5HPPnNHaTatMCDogTr021HS+Spx9d15
M1erJ5Y9e1dEZ4iYe4QNlZQJbSfujxIWVOI175q3k7I3ffRIOPeK66hoWE6wszWP
BM5lcdfksIOVPa61XggfHYMWx7CMsVhsJDvLrWpnJwLZvS+yyxVqWM9CPaMDtYzS
1DFq8QzYdoMaFiIKu7J/JoGExsT8AOuFk2iN5zHXI0uetkQhgQKCAQEA7NSWQtOp
UGuZUinqL5QHNn8cLSzFF2MTEaukc5d82R+ovvGtC2/nQbh9Fw7wzCsk8EckWs5K
O+8s3DZU42fDgzAmFyMY20yyk5Epr4K1gU5aT6VFj5SopGc2iPf/lbYzbwPMlbX4
IpRQpcOh/A11Q5eciO/G+yocoJTUZj5r1HSgl1vnroXr3ipRFzWA5P9QG9J+ARjw
RNPspdYikvwEa/LPHf0IzDY2TvKJ3GNIVAc8SdmEs6bbiExo8E6mzVgcCUW1iXiC
hsT8/AbMmCVGilHPWvzDZ6Mg/I6IR7ngEbKPIeh4OpPzKhxclEVaqFLl9t3TdUY3
yjlQlCMxXMBvFQKCAQEAwFJydLmtXZzMnVD5r+DGsrLgCqQmdKHV2N4l6qUJi1Xf
lOQbpMh6FVywmWhWGiOlZ6JusdlS6PWnGdBX8fp11gTKNqPBZjtBiynIKuOIRv/5
Dg9QORJ4GRB+YSz7UPkUxawmjqX+9SHUbjSA1b287SiOqay/8U+adHuSyiyb9/Xv
FTraVSi4cgwwALPgi544WI78dns3qmwdzW8wxIBYsLkuKNoD2jLVGwD0WluVIC2D
+ygFklsotXfrt1dwQGSzmKXFWXhe6e3MczYsQterixSOLxpjV6iqNf0HzznA1O+M
LdaKa1nh4Kx0uA7Pg13GRce5dwI+cZpIx3oQUHiqoQKCAQBh64/pN4yk9KgjAbOT
Ungc/zdOMlmXTt5+iuJm7LaxK1bhBBnRRcZvNPF7jDDhqeEO+8s7RFoLJ9v7fPdi
Qu/imRDWtis72R1oyDddGwUi3puWnLYDJKsJtqckwK1LYFcpyn9XUzKyUXJ650Vd
as8WUGRndgRmqsXFW2PqllqNMF+J1XUYgsTASezXVMbjZ/DeYAy7NW16d8k71Crd
bRgC7hTWyF1P2gZgZAW+OsqX+5ngyvAEO7Q/SaCF428yLgEBkHH70fjd8irLpaAI
NDlTTXoXm7TLb/Zeliwgw+iySIrHsU+soKmEus9Ieth+CjCM/rDEp3C/yyzW4yow
uFv1AoIBAQCs5THaN9ZgUwcr4lElyTg4DFEXn+yma5sZJNUHdij3Z1LhwpQLduMx
8yscLs1BIIyhd0h2gH5lyfnYXhHCCz0GEzZLGrpSErFgz0YRMI4h7hrThfbD6BOp
zMcgy1R/vfzrnFDpatejPi+Ah5jPDo0a05w1c4T3Bm4/i5wyPxpUN3chKdCAQGRY
uxYoe41BVxMmohza0vj/L8U3G1/HN+8DqOaTeTN/ZLVqWlhSJwUQcXq2PouLwIH/
afix0Vyrtxwx/c1GGs2RFeP4K1ptGQAxv1/3foySkKG8eWYc6LmvdE8cIkQ8MAi9
d6xSj5YEbjhCIURdddn3PRFyqwyhm2khAoIBAQDaNL5bzkRVx3h3R+CkuNvsrBVT
Mg6sEebgy31bYdZu0r4ytOFJjR7zAU0sHhB1VVKePp3tAqKNeoFt1R2PDvuvgRSr
7ecRRr1wxoCgJz/6AJUR/LjzjlTWUCTV0dnZ2L8s4C0XmUyLbIqD09qWb0x2UjDw
WUT7vxoOMykNsW4iOwu9QfF62tRmWmuSzkTiQXpmE2mM3KCzuDCb7KgWkCbAM4R6
Pg5mJ5/B4q9LKyV/Jd6liGKhBM3RvsyjULAR5G7toSZfR/iT0IDsK1whd9wYfDs8
n/Ev/zOXmrBn3FbcNjcCnpk0E7bn7bfXS3+juHpB2BcgF54b3UhLgKLlLfKP
-----END RSA PRIVATE KEY-----
`,

}

