<template>

    <div>
        <label class="label">{{ data.title }}</label>
        <div class="file">
            <label class="file-label">
            <input class="file-input" type="file" id="file" ref="file" v-on:change="fileUpload" name="File"/>
            <span class="file-cta">
                <span class="file-label">Select</span>
            </span>
            </label>
        </div>
        <div v-if="submitOk">
            <label class="label is-inline">{{ data.documentName }} Name: </label> {{this.myfileName}}<br/>
            <label class="label is-inline">Size: </label> {{this.myfileMB}} MB<br/>
            <div v-if="erroredimensioni" class="has-text-danger">{{ data.documentName }} too large. The {{ data.documentName }} size must be less than 4.5 MB</div>
            <div v-if="uploading" class="progress is-small is-primary" max="100">
            <progress class="progress is-primary" v-bind:value="progress" max="100"></progress>
            </div>
        </div>
    </div>

  
</template>
  
<script>
    import apiclient from '@/apiclient.js'
  
    export default {
      name: 'bulmaUpload',
      props: {
        show: Boolean,
        id: String,
        tipoFile: String
      },
      data: function () {
        return {
          submitOk: false,
          myfile: {},
          fileData: '',
          progress: 0,
          uploading: false,
          myfileName: '',
          myfileMB: 0,
          fileSizeWithBuffer: 0,
          bufferSize: 0
        }
      },
      computed: {
        erroredimensioni: function() {
          if (!this.myfile) return false;
          if (this.myfile.size<=5242880 && this.bufferSize <=5242880) return false;
          return true;
        },
        data: function() {
          return this.$store.getters.getObject(this.id).data;
        },
      },
      mounted() {
        this.setDefaults()
      },
      methods: {
        fileUpload: function() {
          if (this.$refs.file.files.length>0) {
            this.submitOk=true;
            this.myfile=this.$refs.file.files[0];
            console.log('this.myfile',this.myfile);
            this.myfileName = this.myfile.name;
            this.myfileMB = this.myfile.size / 1000000
            this.bufferSize = 0
            console.log(this.myfile);
            const reader = new FileReader();
            reader.onload = this.doReadFile
            reader.readAsDataURL(this.myfile);
          } else {
            console.log('nessun file')
            this.submitOk = false
          }
        },
        uploadProgress: function(ev) {
          console.log('uploadProgress');
          console.log('ev',ev);
          this.fileSizeWithBuffer = ev.total
          console.log('this.fileSizeWithBuffer', this.fileSizeWithBuffer)
          this.progress = ev.loaded / ev.total * 100;        
        },
        doReadFile: function (e) {
          console.log('doReadFile bulmaUpload');
          console.log(e);
          this.myfile.fileData=e.target.result.split(',')[1];
          this.$store.commit("SET_FILE_INFO", this.myfile);
        },
        doUpload: async function() {
          console.log('--> bulma_upload.vue doUpload')
          this.uploading=true;
          var self=this;
          var tipo = this.tipoFile? this.tipoFile : '';
          console.log('tipoFile: '+this.tipoFile)
  
          this.$nextTick(function () {
            self.$store.dispatch('loginbyid').then(function() {
              console.log('doUpload');
              var data={ username: self.$store.state.connection.username, callname:'uploadDocument', crypt: self.$store.state.connection.userdata,
                parentId: self.id,  filename: self.myfile.name,  payload: self.fileData, tipoFile: tipo
              };
              console.log('payload:self.fileData',self.fileData)
  
              apiclient.getcandidatura( self.$store.state.connection, data, self.uploadProgress)
              .then( function(resp) {
                console.log('doUpload:');
                console.log(resp)
                self.$emit('submit', resp);
                self.myfile={}; // questo azzeramento fa apparire la scritta DimensioniEccessive se uno riapre il modale
                self.fileData='';
                self.progress=0;
                self.uploading=false;
                self.submitOk = false
              }).catch(err => { 
                console.log('getcandidatura, callname: uploadDocument, err ',err)
                self.uploading = false
                self.progress = 0
                self.bufferSize = self.fileSizeWithBuffer
               });
            });
          });
        },
        setDefaults: function() {
          let s=this.$store.getters.getProcessStatus;
          console.log('getProcessStatus for defaults', s);
          if (s && s.defaults) {
            console.log("defaults!!!");
            s.defaults.forEach( (d) => {
              console.log("defaults", d);
              let dval=''
              if (d.default) dval=d.default
              if (d.previous) {
                let s = this.$store.state.global_state_process;            
                let r=this.$store.state.records_data[s]        
                
                dval= r[d.previous];
              }
              this.$store.commit('SET_FIELD_VALUE', { fieldname: d.fieldname, fieldvalue: dval } );
            })
          }
        },
      }
    }
  </script>
  
  <style scoped>
  </style>